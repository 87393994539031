








































































































































































































































































































































































































































import { Messages } from "@/models/enums/messages.enum";
import {
  DataRegion,
  AddressDataList,
} from "@/models/interface/contact.interface";
import { contactServices } from "@/services/contact.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { debounceProcess } from "@/helpers/debounce";
import Vue from "vue";
import { Mutations } from "@/models/enums/mutations.enum";
import store from "@/store";
import { ADDRESS_DETAIL_OPT } from "./enums/option.enum";
import { IAddressDataList } from "@/models/interface-v2/contact.interface";

interface PlainOptions {
  label: string;
  value: string;
  disabled: boolean;
}

export default Vue.extend({
  name: "AddressDetails",
  props: ["dataTaxType"],
  data() {
    this.searchRegion = debounceProcess(this.searchRegion, 400);
    return {
      mode: "create" as string,
      cardBeforeUpdate: {} as AddressDataList,
      listBillTo: this.$store.state.contactStore
        .listBillTo as IAddressDataList[],
      listShipTo: this.$store.state.contactStore
        .listShipTo as IAddressDataList[],
      loadingSave: false as boolean,
      loadingFetch: false as boolean,
      dataRegion: [] as DataRegion[],
      search: "" as string,
      params: "" as string,
      addressDataList: [] as AddressDataList[],
      // valueRadio: 'shipTo' as string,
      plainOptions: [
        {
          value: ADDRESS_DETAIL_OPT.SHIP_TO,
          disabled: false,
        },
        {
          value: ADDRESS_DETAIL_OPT.BILL_TO,
          disabled: false,
        },
      ] as PlainOptions[],
      valueCheckbox: ["Ship To"] as string[],
      titleForm: this.$t("lbl_add_new_address").toString(),
      form: this.$form.createForm(this, { name: "addressDetails" }),
      formRules: {
        city: {
          label: this.$t("lbl_city_district"),
          name: "city",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "city",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        country: {
          label: this.$t("lbl_country"),
          name: "country",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "country",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        postalCode: {
          label: this.$t("lbl_postal_code"),
          name: "postalCode",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "postalCode",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        address: {
          label: this.$t("lbl_address"),
          name: "address",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "address",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        taxType: {
          label: this.$t("lbl_tax_type"),
          name: "taxType",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "taxType",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      viewModal: false,
      dtSourceContact: [] as any[],
      selectedRowKeys: [] as string[]
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
      };
    },
  },
  created() {
    if (this.dataRegion.length === 0) {
      this.getRegion();
    }
  },
  methods: {
    onSelectChange(e): void {
      this.selectedRowKeys = e;
    },
    addRowContact(): void {
      const { dtSourceContact } = this;
      const row = {
        key: Date.now().toString(),
        position: "",
        picName: "",
        phoneNumber: "",
        telpNumber: "",
      };
      this.dtSourceContact = [...dtSourceContact, row];
    },
    deleteRowContact(): void {
      const { dtSourceContact } = this;
      this.dtSourceContact = dtSourceContact.filter(data => !this.selectedRowKeys.includes(data.key));
      this.selectedRowKeys = [];
    },
    generateLabel(key: string | ADDRESS_DETAIL_OPT): string {
      if (key === ADDRESS_DETAIL_OPT.SHIP_TO)
        return this.$t("lbl_ship_to").toString();
      if (key === ADDRESS_DETAIL_OPT.BILL_TO)
        return this.$t("lbl_bill_to").toString();
      return key;
    },
    handleCancel() {
      this.viewModal = false;
      this.valueCheckbox = ["Ship To"];
      this.titleForm = this.$t("lbl_add_new_address").toString();
      this.mode = "create";
      this.plainOptions[0].disabled = false;
      this.plainOptions[1].disabled = false;
      this.cardBeforeUpdate = {} as AddressDataList;
      this.form.resetFields();
    },
    commitToStore() {
      store.commit(
        `contactStore/${Mutations.SET_LIST_BILL_TO}`,
        this.listBillTo
      );
      store.commit(
        `contactStore/${Mutations.SET_LIST_SHIP_TO}`,
        this.listShipTo
      );
    },
    addNewAddress() {
      this.viewModal = true;
      this.titleForm = this.$t("lbl_add_new_address").toString();
      this.mode = "create";
      this.plainOptions[0].disabled = false;
      this.plainOptions[1].disabled = false;
      this.cardBeforeUpdate = {} as AddressDataList;
      this.form.resetFields();
    },
    setPrimaryCard(dataCard, index, billOrShip): void {
      this.$confirm({
        title: "Are you sure want to set these item to primary?",
        content: "This items will be set to primary.",
        onOk: () => {
          switch (billOrShip) {
            case "Bill To":
              this.listBillTo.forEach((data) => {
                if (data.billTo) {
                  data.primaryBillTo = false;
                }
              });
              this.listBillTo[index].primaryBillTo = true;
              break;
            case "Ship To":
              this.listShipTo.forEach((data) => {
                if (data.shipTo) {
                  data.primaryShipTo = false;
                }
              });
              this.listShipTo[index].primaryShipTo = true;
              break;
            default:
              break;
          }
          this.listShipTo = this.listShipTo.slice();
          this.listBillTo = this.listBillTo.slice();
          this.commitToStore();
        },
        onCancel() {
          return;
        },
      });
    },
    updateCard(dataCard, index, billOrShip: "Bill To" | "Ship To"): void {
      this.viewModal = true;
      this.titleForm = this.$t("lbl_update_address").toString();
      this.mode = "update";
      this.cardBeforeUpdate = dataCard;
      const idtimeout = setTimeout(() => {
        this.form.setFieldsValue({
          country: dataCard.country,
          city: dataCard.cityDistrict,
          postalCode: dataCard.postalCode,
          address: dataCard.address,
          picName: dataCard.picName,
          picContactNumber: dataCard.picContactNumber,
          idCardNumber: dataCard.idCardNumber,
          taxType: dataCard.taxType,
        });
        clearTimeout(idtimeout);
      }, 300);
      if (dataCard.contactDataList) {
        this.dtSourceContact = dataCard.contactDataList.map((x, i) => ({
          key: i,
          email: x.email,
          position: x.position,
          picName: x.picName,
          phoneNumber: x.phoneNumber,
          telpNumber: x.telpNumber,
        }));
      } else {
        this.dtSourceContact = [];
      }
      switch (billOrShip) {
        case "Bill To":
          this.plainOptions[0].disabled = true;
          this.plainOptions[1].disabled = false;
          this.valueCheckbox = ["Bill To"];
          break;
        case "Ship To":
          this.plainOptions[0].disabled = false;
          this.plainOptions[1].disabled = true;
          this.valueCheckbox = ["Ship To"];
          break;
        default:
          break;
      }
    },
    deleteCard(dataCard, index, billOrShip): void {
      this.$confirm({
        title: "Are you sure want to delete these item?",
        content: "This items will be deleted.",
        onOk: () => {
          switch (billOrShip) {
            case "Bill To":
              this.listBillTo = this.listBillTo.filter((data, indexBillTo) => {
                if (index !== indexBillTo) {
                  return data;
                } else {
                  return;
                }
              });
              this.$message.success(
                Messages.DELETE_SUCCESS + " Please Save Data !"
              );
              break;
            case "Ship To":
              this.listShipTo = this.listShipTo.filter((data, indexShipTo) => {
                if (index !== indexShipTo) {
                  return data;
                } else {
                  return;
                }
              });
              this.$message.success(
                Messages.DELETE_SUCCESS + " Please Save Data !"
              );
              break;
            default:
              break;
          }
          this.commitToStore();
        },
        onCancel() {
          return;
        },
      });
    },
    checkDuplicate(values, shipToOrBillTo): number {
      const data =
        shipToOrBillTo === "Ship To" ? this.listShipTo : this.listBillTo;
      return data.findIndex(
        (item) =>
          item.country === values.country &&
          item.cityDistrict === values.city.split("@")[0] &&
          item.postalCode === values.postalCode &&
          item.idCardNumber.toLowerCase() ===
            values.idCardNumber.toLowerCase() &&
          item.taxType === values.taxType
      );
    },
    handleCreate(values) {
      if (this.valueCheckbox.includes("Ship To")) {
        let checkDataByfieldShipTo = this.checkDuplicate(values, "Ship To");
        if (checkDataByfieldShipTo === -1) {
          this.listShipTo = [
            ...this.listShipTo,
            {
              shipTo: true,
              billTo: false,
              primaryShipTo: !this.listShipTo.find(
                (data) => data.shipTo === true
              )
                ? true
                : false,
              primaryBillTo: false,
              country: values.country,
              cityDistrict: values.city.split("@")[0], // combination index 0
              postalCode: values.postalCode, // clear
              address: values.address,
              contactDataList: this.dtSourceContact.map(x => ({
                email: x.email,
                position: x.position,
                picName: x.picName,
                phoneNumber: x.phoneNumber,
                telpNumber: x.telpNumber,
              })),
              picName: values.picName,
              picContactNumber: values.picContactNumber,
              idCardNumber: values.idCardNumber,
              taxType: values.taxType,
            },
          ];
          this.$message.success(
            Messages.CREATE_SUCCESS + " Please Save Data !"
          );
        } else this.$message.error("Ship To already exist");
      }
      if (this.valueCheckbox.includes("Bill To")) {
        let checkDataByfieldBillTo = this.checkDuplicate(values, "Bill To");
        if (checkDataByfieldBillTo === -1) {
          this.listBillTo = [
            ...this.listBillTo,
            {
              shipTo: false,
              billTo: true,
              primaryShipTo: false,
              primaryBillTo: !this.listBillTo.find(
                (data) => data.billTo === true
              )
                ? true
                : false,
              country: values.country,
              cityDistrict: values.city.split("@")[0], // combination index 0
              postalCode: values.postalCode, // clear
              address: values.address,
              picName: values.picName,
              picContactNumber: values.picContactNumber,
              idCardNumber: values.idCardNumber,
              taxType: values.taxType,
              contactDataList: this.dtSourceContact.map(x => ({
                email: x.email,
                position: x.position,
                picName: x.picName,
                phoneNumber: x.phoneNumber,
                telpNumber: x.telpNumber,
              })),
            },
          ];
          this.$message.success(
            Messages.CREATE_SUCCESS + " Please Save Data !"
          );
        } else this.$message.error("Bill To already exist");
      }
    },
    checkUpdateShipToOrBillTo(shipToOrBillTo): number | null {
      let index = null as number | null;
      let dataForLoop =
        shipToOrBillTo === "Ship To" ? this.listShipTo : this.listBillTo;
      dataForLoop.forEach((value, indexData) => {
        if (
          value.shipTo === this.cardBeforeUpdate.shipTo &&
          value.billTo === this.cardBeforeUpdate.billTo &&
          value.idCardNumber === this.cardBeforeUpdate.idCardNumber &&
          value.taxType === this.cardBeforeUpdate.taxType &&
          value.primaryShipTo === this.cardBeforeUpdate.primaryShipTo &&
          value.primaryBillTo === this.cardBeforeUpdate.primaryBillTo &&
          value.country === this.cardBeforeUpdate.country &&
          value.cityDistrict === this.cardBeforeUpdate.cityDistrict &&
          value.postalCode === this.cardBeforeUpdate.postalCode &&
          value.address === this.cardBeforeUpdate.address &&
          value.picName === this.cardBeforeUpdate.picName &&
          value.picContactNumber === this.cardBeforeUpdate.picContactNumber
        ) {
          index = indexData;
        }
      });
      return index;
    },
    handleUpdateShipTo(indexForUpdate, values) {
      this.listShipTo[indexForUpdate] = {
        shipTo: true,
        billTo: false,
        primaryShipTo: !this.listShipTo.find((data) => data.shipTo === true)
          ? true
          : false,
        primaryBillTo: false,
        country: values.country,
        cityDistrict: values.city.split("@")[0], // combination index 0
        postalCode: values.postalCode, // clear
        address: values.address,
        picName: values.picName,
        picContactNumber: values.picContactNumber,
        idCardNumber: values.idCardNumber,
        taxType: values.taxType,
        contactDataList: this.dtSourceContact.map(x => ({
          email: x.email,
          position: x.position,
          picName: x.picName,
          phoneNumber: x.phoneNumber,
          telpNumber: x.telpNumber,
        })),
      };
      this.cardBeforeUpdate = this.listShipTo[indexForUpdate];
      this.$message.success(Messages.UPDATE_SUCCESS + " Please Save Data !");
      this.valueCheckbox = ["Ship To"];
      this.titleForm = this.$t("lbl_add_new_address").toString();
      this.mode = "create";
      this.plainOptions[0].disabled = false;
      this.plainOptions[1].disabled = false;
      this.cardBeforeUpdate = {} as AddressDataList;
      this.form.resetFields();
      this.dtSourceContact = [];
    },
    handleUpdateBillTo(indexForUpdate, values) {
      this.listBillTo[indexForUpdate] = {
        shipTo: false,
        billTo: true,
        primaryShipTo: false,
        primaryBillTo: !this.listBillTo.find((data) => data.shipTo === true)
          ? true
          : false,
        country: values.country,
        cityDistrict: values.city.split("@")[0], // combination index 0
        postalCode: values.postalCode, // clear
        address: values.address,
        picName: values.picName,
        picContactNumber: values.picContactNumber,
        idCardNumber: values.idCardNumber,
        taxType: values.taxType,
        contactDataList: this.dtSourceContact.map(x => ({
          email: x.email,
          position: x.position,
          picName: x.picName,
          phoneNumber: x.phoneNumber,
          telpNumber: x.telpNumber,
        })),
      };
      this.$message.success(Messages.UPDATE_SUCCESS + " Please Save Data !");
      this.cardBeforeUpdate = this.listBillTo[indexForUpdate];
      this.valueCheckbox = ["Ship To"];
      this.titleForm = this.$t("lbl_add_new_address").toString();
      this.mode = "create";
      this.plainOptions[0].disabled = false;
      this.plainOptions[1].disabled = false;
      this.cardBeforeUpdate = {} as AddressDataList;
      this.form.resetFields();
      this.dtSourceContact = [];
    },
    submitForm(e: Event): void {
      e.preventDefault();
      let indexShipTo = null as number | null;
      let indexBillTo = null as number | null;
      this.loadingSave = true;
      this.form.validateFields((err, values) => {
        if (err && this.valueCheckbox.length < 1) {
          this.$notification.error({
            message: "Error",
            description: "Choose 1 Ship To or Bill To",
          });
          return;
        }
        switch (this.mode) {
          case "create":
            this.handleCreate(values);
            this.form.resetFields();
            this.dtSourceContact = [];
            this.valueCheckbox = ["Ship To"];
            break;
          case "update":
            // check updatenya shipTo / billTo
            if (this.cardBeforeUpdate.shipTo) {
              indexShipTo = this.checkUpdateShipToOrBillTo("Ship To");
            } else if (this.cardBeforeUpdate.billTo) {
              indexBillTo = this.checkUpdateShipToOrBillTo("Bill To");
            }
            // check indexnya
            if (indexShipTo !== null) {
              this.handleUpdateShipTo(indexShipTo, values);
            } else if (indexBillTo !== null) {
              this.handleUpdateBillTo(indexBillTo, values);
            } else {
              this.$notification.error({
                message: "Error",
                description: "Data yang ingin diupdate tidak ditemukan",
              });
            }
            break;
        }
      });
      this.listBillTo = this.listBillTo.slice();
      this.listShipTo = this.listShipTo.slice();
      this.commitToStore();
      this.loadingSave = false;
    },
    getRegion(): void {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      this.loadingFetch = true;
      contactServices
        .listRegion(params)
        .then((res) => {
          this.dataRegion = res.data;
        })
        .finally(() => (this.loadingFetch = false));
    },
    searchRegion(value: string): void {
      if (value.length >= 3) {
        this.search = `district~*${value}*_OR_city~*${value}*_OR_province~*${value}*_OR_code~*${value}*`;
        this.getRegion();
      }
    },
    handleChangeRegion(value) {
      if (value) {
        this.form.setFieldsValue({
          postalCode: value.split("@")[1],
        });
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
});
