var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { paddingLeft: "1rem", paddingRight: "1rem" } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { sm: 24, md: 12 } }, [
            _c("h2", [_vm._v(" " + _vm._s(_vm.$t("lbl_list_address")) + " ")])
          ]),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: { icon: "plus", type: "primary" },
                  on: { click: _vm.addNewAddress }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_add_new_address")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", [
            _vm.listShipTo.length === 0
              ? _c(
                  "div",
                  { staticStyle: { "overflow-y": "scroll", height: "140px" } },
                  [
                    _c("a-empty", {
                      attrs: { description: _vm.$t("lbl_no_data_ship_to") }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("h3", [_vm._v(" " + _vm._s(_vm.$t("lbl_ship_address")) + " ")]),
            _vm.listShipTo.length !== 0
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "overflow-y": "scroll",
                      "max-height": "250px"
                    }
                  },
                  _vm._l(_vm.listShipTo, function(dataShipTo, index) {
                    return _c(
                      "a-card",
                      { key: index, staticClass: "customCard" },
                      [
                        dataShipTo.primaryShipTo
                          ? _c(
                              "a-tag",
                              { attrs: { color: "#108ee9" } },
                              [
                                _c("a-icon", {
                                  staticStyle: { "vertical-align": "0" },
                                  attrs: { type: "tags" }
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_primary")) + " "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              marginBottom: "0",
                              fontWeight: "bold"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_ship_to")) + " ")]
                        ),
                        _c("p", { staticStyle: { marginBottom: "0" } }, [
                          _vm._v(" " + _vm._s(dataShipTo.address) + " ")
                        ]),
                        _c("p", { staticStyle: { marginBottom: "0" } }, [
                          _vm._v(" " + _vm._s(dataShipTo.cityDistrict) + " ")
                        ]),
                        _c("p", { staticStyle: { marginBottom: "0" } }, [
                          _vm._v(" " + _vm._s(dataShipTo.country) + " ")
                        ]),
                        _c("p", { staticStyle: { marginBottom: "0" } }, [
                          _vm._v(" " + _vm._s(dataShipTo.postalCode) + " ")
                        ]),
                        _c(
                          "a-row",
                          {
                            staticStyle: {
                              justifyContent: "flex-end",
                              marginTop: "1rem"
                            },
                            attrs: { gutter: [16, 16], type: "flex" }
                          },
                          [
                            _c(
                              "a-col",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890ff"
                                },
                                attrs: {
                                  span: "6",
                                  xs: 24,
                                  sm: 24,
                                  md: 8,
                                  lg: 24,
                                  xl: 6
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.setPrimaryCard(
                                      dataShipTo,
                                      index,
                                      "Ship To"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lbl_set_as_primary")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#52c41a"
                                },
                                attrs: {
                                  span: "6",
                                  xs: 24,
                                  sm: 24,
                                  md: 8,
                                  lg: 24,
                                  xl: 6
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.updateCard(
                                      dataShipTo,
                                      index,
                                      "Ship To"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#f5222d"
                                },
                                attrs: {
                                  span: "6",
                                  xs: 24,
                                  sm: 24,
                                  md: 8,
                                  lg: 24,
                                  xl: 6
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.deleteCard(
                                      dataShipTo,
                                      index,
                                      "Ship To"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_delete")) + " ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e()
          ]),
          _c("a-col", [
            _vm.listBillTo.length === 0
              ? _c(
                  "div",
                  { staticStyle: { "overflow-y": "scroll", height: "140px" } },
                  [
                    _c("a-empty", {
                      attrs: { description: _vm.$t("lbl_no_data_bill_to") }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("h3", [_vm._v(" " + _vm._s(_vm.$t("lbl_bill_address")) + " ")]),
            _vm.listBillTo.length !== 0
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "overflow-y": "scroll",
                      "max-height": "250px"
                    }
                  },
                  _vm._l(_vm.listBillTo, function(dataBillTo, index) {
                    return _c(
                      "a-card",
                      { key: index, staticClass: "customCard" },
                      [
                        dataBillTo.primaryBillTo
                          ? _c(
                              "a-tag",
                              { attrs: { color: "#108ee9" } },
                              [
                                _c("a-icon", {
                                  staticStyle: { "vertical-align": "0" },
                                  attrs: { type: "tags" }
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_primary")) + " "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              marginBottom: "0",
                              fontWeight: "bold"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_bill_to")) + " ")]
                        ),
                        _c("p", { staticStyle: { marginBottom: "0" } }, [
                          _vm._v(" " + _vm._s(dataBillTo.address) + " ")
                        ]),
                        _c("p", { staticStyle: { marginBottom: "0" } }, [
                          _vm._v(" " + _vm._s(dataBillTo.cityDistrict) + " ")
                        ]),
                        _c("p", { staticStyle: { marginBottom: "0" } }, [
                          _vm._v(" " + _vm._s(dataBillTo.country) + " ")
                        ]),
                        _c("p", { staticStyle: { marginBottom: "0" } }, [
                          _vm._v(" " + _vm._s(dataBillTo.postalCode) + " ")
                        ]),
                        _c(
                          "a-row",
                          {
                            staticStyle: {
                              justifyContent: "flex-end",
                              marginTop: "1rem"
                            },
                            attrs: { gutter: [16, 16], type: "flex" }
                          },
                          [
                            _c(
                              "a-col",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890ff"
                                },
                                attrs: {
                                  span: "6",
                                  xs: 24,
                                  sm: 24,
                                  md: 8,
                                  lg: 24,
                                  xl: 6
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.setPrimaryCard(
                                      dataBillTo,
                                      index,
                                      "Bill To"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lbl_set_as_primary")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#52c41a"
                                },
                                attrs: {
                                  span: "6",
                                  xs: 24,
                                  sm: 24,
                                  md: 8,
                                  lg: 24,
                                  xl: 6
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.updateCard(
                                      dataBillTo,
                                      index,
                                      "Bill To"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#f5222d"
                                },
                                attrs: {
                                  span: "6",
                                  xs: 24,
                                  sm: 24,
                                  md: 8,
                                  lg: 24,
                                  xl: 6
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.deleteCard(
                                      dataBillTo,
                                      index,
                                      "Bill To"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_delete")) + " ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.titleForm, width: 800 },
          model: {
            value: _vm.viewModal,
            callback: function($$v) {
              _vm.viewModal = $$v
            },
            expression: "viewModal"
          }
        },
        [
          _c(
            "a-form",
            _vm._b(
              {
                attrs: { layout: "vertical", form: _vm.form },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submitForm.apply(null, arguments)
                  }
                }
              },
              "a-form",
              _vm.formItemLayout,
              false
            ),
            [
              _c("a-checkbox-group", {
                staticStyle: { marginTop: "1rem", marginBottom: "2rem" },
                attrs: { name: "checkboxgroup", options: _vm.plainOptions },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function(ref) {
                      var value = ref.value
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm.generateLabel(value)))
                      ])
                    }
                  }
                ]),
                model: {
                  value: _vm.valueCheckbox,
                  callback: function($$v) {
                    _vm.valueCheckbox = $$v
                  },
                  expression: "valueCheckbox"
                }
              }),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.country.label } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.country.decorator,
                          expression: "formRules.country.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.country.name,
                        placeholder: _vm.formRules.country.placeholder,
                        "allow-clear": true
                      }
                    },
                    [
                      _c(
                        "a-select-option",
                        { attrs: { value: "indonesia" } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" Indonesia ")
                              ]),
                              _vm._v(" Indonesia ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.city.label } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.city.decorator,
                          expression: "formRules.city.decorator"
                        }
                      ],
                      attrs: {
                        "show-search": "",
                        name: _vm.formRules.city.name,
                        placeholder: _vm.formRules.city.placeholder,
                        "filter-option": _vm.filterOption,
                        "option-filter-prop": "children",
                        loading: _vm.loadingFetch,
                        "allow-clear": true
                      },
                      on: {
                        search: _vm.searchRegion,
                        change: _vm.handleChangeRegion
                      }
                    },
                    _vm._l(_vm.dataRegion, function(data, index) {
                      return _c(
                        "a-select-option",
                        {
                          key: index,
                          attrs: { value: data.combination + "@" + data.code }
                        },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(data.combination + "," + data.code) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(data.combination + "," + data.code) +
                                  " "
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.postalCode.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.postalCode.decorator,
                        expression: "formRules.postalCode.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.postalCode.name,
                      placeholder: _vm.formRules.postalCode.placeholder
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.address.label } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.address.decorator,
                        expression: "formRules.address.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.address.name,
                      placeholder: _vm.formRules.address.placeholder,
                      rows: 4
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.taxType.label } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRules.taxType.decorator,
                          expression: "formRules.taxType.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRules.taxType.name,
                        placeholder: _vm.formRules.taxType.placeholder,
                        "allow-clear": true
                      }
                    },
                    _vm._l(_vm.dataTaxType, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.value) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.value) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: { click: _vm.addRowContact }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger", icon: "delete" },
                          on: { click: _vm.deleteRowContact }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dtSourceContact,
                        "row-selection": {
                          selectedRowKeys: _vm.selectedRowKeys,
                          onChange: _vm.onSelectChange
                        },
                        scroll: { y: 320 }
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        {
                          key: "email",
                          attrs: { "data-index": "email" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return _c("a-input", {
                                  attrs: {
                                    "allow-clear": "",
                                    placeholder: _vm.$t("lbl_type_here")
                                  },
                                  model: {
                                    value: record.email,
                                    callback: function($$v) {
                                      _vm.$set(record, "email", $$v)
                                    },
                                    expression: "record.email"
                                  }
                                })
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_email")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "position",
                          attrs: { "data-index": "position" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return _c("a-input", {
                                  attrs: {
                                    "allow-clear": "",
                                    placeholder: _vm.$t("lbl_type_here")
                                  },
                                  model: {
                                    value: record.position,
                                    callback: function($$v) {
                                      _vm.$set(record, "position", $$v)
                                    },
                                    expression: "record.position"
                                  }
                                })
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_position")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "picName",
                          attrs: { "data-index": "picName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return _c("a-input", {
                                  attrs: {
                                    "allow-clear": "",
                                    placeholder: _vm.$t("lbl_type_here")
                                  },
                                  model: {
                                    value: record.picName,
                                    callback: function($$v) {
                                      _vm.$set(record, "picName", $$v)
                                    },
                                    expression: "record.picName"
                                  }
                                })
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_pic_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "phoneNumber",
                          attrs: { "data-index": "phoneNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return _c("a-input", {
                                  attrs: {
                                    type: "tel",
                                    "allow-clear": "",
                                    placeholder: _vm.$t("lbl_type_here")
                                  },
                                  model: {
                                    value: record.phoneNumber,
                                    callback: function($$v) {
                                      _vm.$set(record, "phoneNumber", $$v)
                                    },
                                    expression: "record.phoneNumber"
                                  }
                                })
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_phone_number")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "telpNumber",
                          attrs: { "data-index": "telpNumber" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return _c("a-input", {
                                  attrs: {
                                    type: "tel",
                                    "allow-clear": "",
                                    placeholder: _vm.$t("lbl_type_here")
                                  },
                                  model: {
                                    value: record.telpNumber,
                                    callback: function($$v) {
                                      _vm.$set(record, "telpNumber", $$v)
                                    },
                                    expression: "record.telpNumber"
                                  }
                                })
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_telephone_number")))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
              ]),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary", loading: _vm.loadingSave },
                  on: { click: _vm.submitForm }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.mode === "create"
                          ? _vm.$t("lbl_save")
                          : _vm.$t("lbl_update")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }